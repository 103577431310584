import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/authentication/Login";
import Register from "../pages/authentication/Register";
import Otp from "../pages/authentication/Otp";
import ResetPassword from "../pages/authentication/ResetPassword";
import { GoogleOAuthProvider } from "@react-oauth/google";
import DevOtp from "../pages/authentication/DevOtp";

const AuthRoutes = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/check" element={<DevOtp />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/otp" element={<Otp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </GoogleOAuthProvider>
  );
};

export default AuthRoutes;
