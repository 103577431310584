import React, { useState } from "react";
import BotListCard from "./BotListCard";
import MoreOptions from "./MoreOptions";
import { Howl } from "howler";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { areAlwaysOpen, randomNumber } from "../utils/helper/helperFunctions";
import WhatsAppIcon from "../assets/images/Icons/WhatsAppIcon";
import CallHistoryIcon from "../assets/images/Icons/CallHistoryIcon";
import MeetingsIcon from "../assets/images/Icons/MeetingsIcon";
const apiUrl = process.env.REACT_APP_PYTHON_URL;
const token = sessionStorage.getItem("token")?.toString();
const auth = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

const BotTable = ({
  listOfBots,
  setShowModel,
  setSelectedBotID,
  setSelectedOption,
  setSelectedPhoneNumberSID,
  setSelectedBot,
  setWhatsAppStatus,
  setBotPhoneNumber,
  userData,
}) => {
  const [allOptions] = useState([
    "Message Settings",
    "Duplicate and Create New",
    "Request WhatsApp Service",
    "Edit",
    "Delete",
  ]);
  const navigate = useNavigate();

  const preViewBot = async (botDetails) => {
    let payload = {
      language: botDetails.language,
      voice_name: botDetails.voiceType,
      pitch: botDetails.pitch.toString(),
      speed: botDetails.speed.toString(),
      text: botDetails.greetings[randomNumber(botDetails.greetings.length) - 1]
        .greetText,
    };

    await axios
      .post(`${apiUrl}/bot_preview`, payload, auth)
      .then((res) => {
        // console.log(res.data, "preview");

        let audio = new Howl({
          src: [`${apiUrl}/${res.data.audio_response}`],
          html5: true,
          onend: async () => {
            // console.log("audio completed");
          },
        });
        audio.play();
      })
      .catch((error) => {
        console.log(error, "error");
        toast.error(error.response.data.message, {
          theme: "colored",
          autoClose: 1000,
          progress: false,
          hideProgressBar: true,
          style: { backgroundColor: "red" },
        });
      });
  };

  const retrieveAllFormsData = (botData) => {
    // console.log(botData, "id");
    const botIdentityData = {
      botName: botData?.details.botName,
      botLanguage: botData?.details.language,
      botVoice: botData?.details.voiceType,
      voiceSource: botData?.details?.voiceSource,
      voiceSpeed: botData?.details.speed,
      voicePitch: botData?.details.pitch,
    };
    const botGreetingData = {
      botGreetings: botData?.details.greetings,
    };

    const yourBusinessData = {
      businessName: botData?.details.businessName,
      businessDesc: botData?.details.businessDescription,
      businessOperationStatus: botData?.details.businessOperationStatus,
      totalOperations: botData?.details.businessOperations,
      totalBranches: botData?.details.branches,
      onlyTakeMessages: botData?.details.onlyTakeMessages,
      countryISO: botData?.countryISO || "US",
    };

    const businessFaqData = {
      toggle: areAlwaysOpen(botData?.details?.workingDetails),
      totalWorkingHours: botData?.details.workingDetails,
      businessFAQ: botData?.details.faq,
      timeZone: botData?.details.timeZone,
    };

    localStorage.setItem("editfaqs", JSON.stringify(businessFaqData));
    localStorage.setItem("edityourBusiness", JSON.stringify(yourBusinessData));
    localStorage.setItem("editbotgreeting", JSON.stringify(botGreetingData));
    localStorage.setItem("editBotIdentity", JSON.stringify(botIdentityData));
    localStorage.setItem("botData", JSON.stringify(botData));
  };

  const getBotDataForDuplication = (botDetails) => {
    const botIdentityData = {
      botName: botDetails?.details.botName,
      botLanguage: botDetails?.details.language,
      botVoice: botDetails?.details.voiceType,
      voiceSource: botDetails?.details?.voiceSource,
      voiceSpeed: botDetails?.details.speed,
      voicePitch: botDetails?.details.pitch,
    };
    const botGreetingData = {
      botGreetings: botDetails?.details.greetings,
    };

    const yourBusinessData = {
      businessName: botDetails?.details.businessName,
      businessDesc: botDetails?.details.businessDescription,
      businessOperationStatus: botDetails?.details.businessOperationStatus,
      totalOperations: botDetails?.details.businessOperations,
      totalBranches: botDetails?.details.branches,
      onlyTakeMessages: botDetails?.details.onlyTakeMessages,
      countryISO: botDetails?.countryISO || "US",
    };

    const businessFaqData = {
      toggle: areAlwaysOpen(botDetails?.details?.workingDetails),
      totalWorkingHours: botDetails?.details.workingDetails,
      businessFAQ: botDetails?.details.faq,
      timeZone: botDetails?.details.timeZone,
    };

    localStorage.setItem("dupfaqs", JSON.stringify(businessFaqData));
    localStorage.setItem("dupyourBusiness", JSON.stringify(yourBusinessData));
    localStorage.setItem("dupbotgreeting", JSON.stringify(botGreetingData));
    localStorage.setItem("dupBotIdentity", JSON.stringify(botIdentityData));
  };

  // console.log(listOfBots, "list");

  return (
    <>
      <ToastContainer />
      <div className=" hidden lg:block relative bg-white">
        <div className=" grid grid-cols-7 xl:gap-x-6  bg-secoundry py-2  pl-2 rounded-md mt-2 mx-2">
          <p className=" text-[14.86px] text-white ">Sr. No.</p>
          <p className=" text-[14.86px] text-white ">Name</p>
          <p className=" text-[14.86px] text-white">Business Name</p>
          <p className=" text-[14.86px] text-white">Bot Phone No.</p>
          <p className=" text-[14.86px] text-white ">Active Services </p>
          <p className=" text-[14.86px] text-white ml-5">Status</p>
          <p className=" text-[14.86px] text-white"></p>
        </div>
      </div>
      <div className=" hidden lg:block mx-3 ">
        {listOfBots?.length === 0 ? (
          <div className=" flex items-center justify-center py-5">
            <p className=" text-appgray">You Have No Any Bots</p>
          </div>
        ) : (
          <div className=" my-3">
            {listOfBots?.map((item, index) => (
              <div
                key={index}
                className="  grid grid-cols-7 xl:gap-x-6 mx-2 py-3"
              >
                <p className="  flex text-center text-[14.86px] text-ttt">
                  {index + 1}.
                </p>
                <p className=" text-[14.86px] text-ttt">
                  {item?.details?.botName}
                </p>
                <p className=" text-[14.86px] text-ttt">
                  {item?.details?.businessName}
                </p>
                <p className=" text-[14.86px] text-ttt ">
                  {item?.details?.bot_phone_no_Data?.phoneNumber}
                </p>
                <div className=" text-[14.86px] text-ttt ml-2">
                  <CallHistoryIcon color={"#241c51"} className="inline-block" />{" "}
                  {userData?.refreshToken &&
                  item?.details?.businessOperationStatus ? (
                    <MeetingsIcon color={"#241c51"} className="inline-block" />
                  ) : (
                    ""
                  )}{" "}
                  {item?.whatsAppStatus === "active" ? (
                    <WhatsAppIcon className="w-7 h-7 inline-block" />
                  ) : (
                    ""
                  )}
                </div>

                <p
                  className={`text-[14.86px] ${
                    item?.status === "active"
                      ? "text-green-700"
                      : "text-red-600"
                  } ml-6 capitalize`}
                >
                  {item?.status}
                </p>
                <div className=" flex items-center justify-between">
                  <button
                    onClick={() => {
                      preViewBot(item?.details);
                    }}
                  >
                    <img
                      alt="preview"
                      className=" h-5 w-5 filter text-primary cursor-pointer transition-all duration-300 ease-in-out hover:h-6 hover:w-6"
                      src={require("../assets/images/megaphone.png")}
                    />
                  </button>
                  <MoreOptions
                    setSelectedOption={(option) => {
                      if (option === "Edit") {
                        navigate("/bots/edit-bot");
                        retrieveAllFormsData(item);
                        return;
                      }
                      if (option === "Duplicate and Create New") {
                        getBotDataForDuplication(item);
                        setShowModel(true);
                        setSelectedOption(option);
                        return;
                      }
                      setShowModel(true);
                      setSelectedOption(option);
                      setSelectedBotID(item._id);
                      setSelectedPhoneNumberSID(
                        item?.details?.bot_phone_no_Data?.sid
                      );
                      setSelectedBot(item);
                      setWhatsAppStatus(item?.whatsAppStatus || "");
                      setBotPhoneNumber(
                        item?.details?.bot_phone_no_Data?.phoneNumber || ""
                      );
                    }}
                    status={item.status}
                    options={allOptions}
                    className=" right-10 "
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="  lg:hidden mx-3  ">
        {listOfBots?.length === 0 ? (
          <div className=" flex items-center justify-center py-5">
            <p className=" text-appgray">You Have No Any Bots</p>
          </div>
        ) : (
          <div>
            {listOfBots?.map((item, index) => (
              <div key={index}>
                <BotListCard
                  item={item}
                  index={index}
                  onDelete={(r) => {
                    setShowModel(true);
                    setSelectedOption("Delete");
                    setSelectedPhoneNumberSID(
                      item?.details?.bot_phone_no_Data?.sid
                    );
                    setSelectedBotID(item._id);
                  }}
                  onEdit={() => {
                    navigate("/bots/edit-bot");
                    retrieveAllFormsData(item);
                  }}
                  onSettings={() => {
                    setShowModel(true);
                    setSelectedOption("Message Settings");
                    setSelectedBotID(item._id);
                    setSelectedBot(item);
                  }}
                  onPreview={() => preViewBot(item?.details)}
                  onDuplicate={() => {
                    getBotDataForDuplication(item);
                    setShowModel(true);
                    setSelectedOption("Duplicate and Create New");
                  }}
                  onWhatsApp={() => {
                    setSelectedBotID(item._id);
                    setShowModel(true);
                    setSelectedOption("Request WhatsApp Service");
                    setWhatsAppStatus(item?.whatsAppStatus || "");
                    setBotPhoneNumber(
                      item?.details?.bot_phone_no_Data?.phoneNumber || ""
                    );
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default BotTable;
