import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import PasswordInput from "../../components/PasswordInput";
import { useFormik } from "formik";
import { registerSchema } from "../../utils/schemas";
import BlurAnimation from "../../components/BlurAnimation";
import CustomCheckBox from "../../components/CustomCheckBox";
//import emailjs from "@emailjs/browser";
import CircularLoader from "../../components/CircularLoader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useGoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../components/CustomModal";
import { Puff } from "react-loader-spinner";
import { setAccessToken } from "../../redux/authSlice";

const Register = () => {
  const pakaData = useSelector((state) => state.content.pakaData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkbox, setCheckbox] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [googleLoader, setGoogleLoader] = useState(false);
  const [checkEmail, setCheckEmail] = useState("");
  const {
    handleChange,
    handleReset,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: registerSchema,
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      rePassword: "",
    },
    onSubmit: () => {
      register();
    },
  });

  const signIn = useGoogleLogin({
    onSuccess: async (user) => {
      setGoogleLoader(true);
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/auth/login_with_google`,
          {
            token: user.access_token,
          },
          {
            withCredentials: true,
          }
        )
        .then(async (googleResp) => {
          // console.log(result);
          if (googleResp.data?.message === "Email Not Exist") {
            let userinfo = jwt_decode(googleResp.data.token);
            // console.log(userinfo, "signup now");
            const payload = {
              firstname: userinfo.given_name,
              lastname: userinfo?.family_name || "",
              email: userinfo.email,
              password: "",
              phone: 0,
              country: "",
              address: {
                city: "",
                street: "",
                zipcode: 0,
              },
              timeZone: {
                value: "Etc/GMT",
                label: "(GMT+0:00) UTC",
                offset: 0,
                abbrev: "GMT",
                altName: "British Standard Time",
              },
              businessInfo: {
                businessName: "",
                businessEmail: "",
                vatID: "",
                fullAdress: "",
              },
            };

            // console.log(payload, "pay");
            await axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/auth/register_with_google`,
                payload,
                {
                  withCredentials: true,
                }
              )
              .then(async (res) => {
                // console.log(res, "signup");
                sessionStorage.setItem("token", res.data.accessToken);
                sessionStorage.setItem("user_id", res.data.user_id);
                dispatch(setAccessToken(res.data.accessToken || ""));
                if (res.data.botInfo.length === 0) {
                  navigate("/create-bot");
                  return;
                }
                if (res.data.botInfo.length > 0) {
                  navigate("/dashboard");
                  return;
                }
              })
              .catch((e) => {
                console.log(e);
                setGoogleLoader(false);
              });
          } else {
            toast.warn("Already Register with this Email", {
              theme: "colored",
              position: "top-center",
              autoClose: 3000,
              progress: false,
              hideProgressBar: true,
            });
            setGoogleLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setGoogleLoader(false);
        });
    },
    onFailure: (error) => {
      console.error("Google login failed!", error);
    },
    scope: "profile email",
    ux_mode: "redirect",
  });

  const EmailOtp = async () => {
    const lowerCaseEmail = values.email.toLowerCase();
    const userData = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: lowerCaseEmail,
      password: values.password,
    };
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/send_otp`, {
        email: lowerCaseEmail,
        emailType: "register",
        emailSubject: "Registration OTP verification",
      })
      .then((res) => {
        // console.log(res);
        const myObjectString = JSON.stringify(userData);
        localStorage.setItem("regData", myObjectString);
        handleReset();
        setLoader(false);
        navigate("/register/otp");
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        // alert("invalid email");
        toast.warn(
          "There is a problem with send email, please try again later",
          {
            theme: "colored",
            position: "top-center",
            autoClose: 4000,
            progress: false,
            hideProgressBar: true,
          }
        );
      });
  };

  const register = async () => {
    if (values.password !== values.rePassword) {
      setPasswordMatchError(true);
      return;
    }
    if (!checkbox) {
      toast.warn("Please Accept Term and Privacy Policy", {
        theme: "colored",
        position: "top-center",
        autoClose: 1000,
        progress: false,
        hideProgressBar: true,
      });
      return;
    }
    setLoader(true);
    EmailOtp();
  };

  // console.log(touched.password, errors.password);
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeegnUlAAAAAGQjFJ-x6o2WYcqNwS_SSG9vBapd">
      <div className=" relative bg-primary min-h-screen">
        <BlurAnimation />
        <ToastContainer />
        <CustomModal isOpen={googleLoader}>
          <div className=" flex items-center justify-center">
            <div className=" bg-white p-8 h-fit w-fit rounded-md">
              <Puff
                height="100"
                width="100"
                radius={1}
                color="#FF0066"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
        </CustomModal>
        <div className="">
          <div className=" sticky top-0 flex items-center justify-between px-8 xl:px-12 py-3">
            <div className=" flex items-center">
              <img
                className=" h-8 w-28"
                alt="logo"
                src={require("../../assets/images/Logo1.png")}
              />
            </div>
            <div className=" hidden lg:block">
              <div className=" flex items-center">
                <h3 className=" text-white">Already, have an account? </h3>
                <button
                  onClick={() => navigate("/")}
                  className=" text-secoundry  px-2 py-1  rounded-md cursor-pointer"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
          <div className=" flex flex-col px-6 md:px-20 items-center pt-3">
            <div className="lg:w-2/5  ">
              <h1 className=" font-bold text-white text-2xl mb-3">
                {/* Get started absolutely free. */}
                {pakaData.signUp.heading}
              </h1>
              <span className=" text-appgray text-center text-sm ">
                {/* Create your own voice bot! */}
                {pakaData.signUp.subHeading}
              </span>
            </div>
            <button
              onClick={signIn}
              className=" border-2  border-appgray rounded-md  items-center w-full lg:w-2/5  flex justify-center py-2 mt-7"
            >
              <FcGoogle className=" text-2xl" />
              <p className=" mx-8 text-white">Continue with Google</p>
            </button>
            <div className="  w-full lg:w-2/5  mt-10 relative ">
              <div className=" h-[0.15rem] w-full bg-inputBg" />
              <p className=" text-appgray bg-primary px-2 py-1 absolute -top-4 left-1/2">
                OR
              </p>
            </div>
            <form
              onSubmit={handleSubmit}
              className=" w-full lg:w-2/5  py-5 select-none"
            >
              <div className=" grid grid-cols-2 gap-x-3">
                <div>
                  <CustomInput
                    placeholder="First Name"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange("firstname")}
                    onBlur={handleBlur("firstname")}
                  />
                  {touched.firstname && errors.firstname ? (
                    <p className=" text-[11px] text-red-500 ml-2 my-1.5">
                      {errors.firstname}
                    </p>
                  ) : (
                    <p className=" my-6"></p>
                  )}
                </div>
                <div>
                  <CustomInput
                    placeholder="Last Name"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange("lastname")}
                    onBlur={handleBlur("lastname")}
                  />
                  {touched.lastname && errors.lastname ? (
                    <p className=" text-[11px] text-red-500 ml-2 my-1.5">
                      {errors.lastname}
                    </p>
                  ) : (
                    <p className=" my-6"></p>
                  )}
                </div>
              </div>
              <CustomInput
                placeholder="Email address"
                name="email"
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
                onFocus={() => setCheckEmail("")}
              />
              <div className=" h-4">
                {touched.email && errors.email ? (
                  <p className=" text-[11px] text-red-500 ml-2 my-1.5">
                    {errors.email}
                  </p>
                ) : (
                  <p className=" text-[11px] text-red-500 ml-2 my-1.5">
                    {checkEmail !== "" && checkEmail}
                  </p>
                )}
              </div>
              <PasswordInput
                placeholder="Enter your password"
                name="password"
                value={values.password}
                onChange={handleChange("password")}
                inputBlur={handleBlur("password")}
              />
              {touched.password && errors.password ? (
                <p className=" text-[11px] text-red-500 ml-2 my-1.5">
                  {errors.password}
                </p>
              ) : (
                <p className=" my-6"></p>
              )}
              <PasswordInput
                placeholder="Re-enter your password"
                name="rePassword"
                value={values.rePassword}
                onChange={handleChange("rePassword")}
                inputBlur={handleBlur("rePassword")}
                inputFocus={() => setPasswordMatchError(false)}
              />
              {touched.rePassword && errors.rePassword ? (
                <p className=" text-[11px] text-red-500 ml-2 my-1.5">
                  {errors.rePassword}
                </p>
              ) : (
                <p className=" text-[11px] text-red-500 ml-2 my-1.5">
                  {passwordMatchError && "Password not matched"}
                </p>
              )}
              <div className=" flex pt-4">
                {/* <input
                type="checkbox"
                className=" h-6 w-6 md:h-4 md:w-4   checked:bg-secoundry mx-2"
              /> */}
                <CustomCheckBox
                  setChecked={setCheckbox}
                  checked={checkbox}
                  className=" mr-2 w-7 md:w-5 lg:w-8 xl:w-5"
                />
                <span
                  className={` ${
                    checkbox ? `text-[#ffffff]` : `text-[#919EAB]`
                  } text-sm  font-normal`}
                >
                  By signing up, I agree to PAKA AI{" "}
                  <span className=" text-[#ffffff] text-sm font-[430] underline">
                    <Link
                      to="https://www.paka.ai/terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span className=" text-[#ffffff] text-sm font-[430] underline">
                    <Link
                      to="https://www.paka.ai/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy.
                    </Link>
                  </span>
                </span>
              </div>
              <div className={`${loader && "flex justify-center"}`}>
                <button
                  type="submit"
                  className={`bg-secoundry transition-all ease-in-out duration-500 ${
                    loader ? " w-16 h-16 rounded-full " : "w-full rounded-md"
                  } hover:bg-pink-600 font-semibold flex justify-center items-center  py-3 mt-5 text-white `}
                >
                  {loader ? (
                    <div>
                      <CircularLoader />
                    </div>
                  ) : (
                    <p>Register</p>
                  )}
                </button>
              </div>
              <div className=" lg:hidden flex justify-center mt-4">
                <div className=" flex items-center">
                  <h3 className=" text-white">Don’t have an account yet? </h3>
                  <button
                    onClick={() => navigate("/")}
                    className=" text-secoundry  px-2 py-1  rounded-md cursor-pointer"
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default Register;
